<template>
  <zw-dialog
    :hidden="hidden"
    :title="title"
    :hideFooter="false"
    :width="660"
    :height="300"
    @close="closeDialog"
    @cancel="closeDialog"
    @confirm="submit"
  >
    <div class="form-wrap" v-loading="loading">
      <div class="form-row">
        <div
          class="form-item"
          :class="[
            { focus: focusIndex == 0 },
            { active: shippingAddress.Name },
          ]"
          @click="focusIndex = 0"
        >
          <el-input
            v-model="shippingAddress.Name"
            maxlength="20"
            :focus="focusIndex == 0"
            @blur="focusIndex = -1"
            show-word-limit
          />
          <span class="placeholder">姓名</span>
        </div>
        <div
          class="form-item"
          :class="[
            { focus: focusIndex == 1 },
            { active: shippingAddress.PhoneNumber },
            { validate: validatePhoneTxt },
          ]"
          @click="focusIndex = 1"
        >
          <el-input
            :value="shippingAddress.PhoneNumber"
            type="text"
            maxlength="11"
            :placeholder="focusIndex == 1 ? oldPhone : ''"
            placeholder-style="color: #b0b0b0;"
            :focus="focusIndex == 1"
            @blur="checkPhone"
           @input="inputPhone"
            show-word-limit
          >
          </el-input>
          <span class="placeholder">{{
            validatePhoneTxt ? validatePhoneTxt : "手机号"
          }}</span>
        </div>
      </div>
      <div class="form-row" @click="hiddenCityPicker = false">
        <div class="form-item address">
          <el-input
            :value="mainAddress"
            placeholder="选择省 / 市 / 区 / 街道"
            readonly="true"
          />
          <i class="icon i-right" style="cursor: pointer"></i>
        </div>
      </div>
      <div class="form-row">
        <div
          class="form-item long-text"
          :class="[
            { focus: focusIndex == 2 },
            { active: shippingAddress.Address },
          ]"
          @click="focusIndex = 2"
        >
          <el-input
            type="textarea"
            class="textarea"
            v-model="shippingAddress.Address"
            maxlength="50"
            :placeholder="
              focusIndex == 2 ? '详细地址，路名或街道名称，门牌号' : ''
            "
            placeholder-style="color: #b0b0b0;"
            :focus="focusIndex == 2"
            @blur="focusIndex = -1"
            show-word-limit
          ></el-input>
          <span class="placeholder">详细地址</span>
        </div>
      </div>
      <div class="form-row">
        <div
          class="form-item"
          :class="[{ focus: focusIndex == 3 }, { active: shippingAddress.Tag }]"
          @click="focusIndex = 3"
        >
          <el-input
            v-model="shippingAddress.Tag"
            maxlength="5"
            :placeholder="
              focusIndex == 3
                ? '如&quot;家&quot;、&quot;公司&quot;。限5个字内'
                : ''
            "
            placeholder-style="color: #b0b0b0;"
            :focus="focusIndex == 3"
            @blur="focusIndex = -1"
            show-word-limit
          />
          <span class="placeholder">地址标签</span>
        </div>
      </div>
      <div class="form-row">
        <div
          class="form-item"
          :class="[
            { focus: focusIndex == 4 },
            { active: shippingAddress.PostCode },
          ]"
          @click="focusIndex = 4"
        >
          <el-input
            v-model="shippingAddress.PostCode"
            maxlength="6"
            :placeholder="focusIndex == 4 ? '邮政编号' : ''"
            placeholder-style="color: #b0b0b0;"
            :focus="focusIndex == 4"
            @blur="focusIndex = -1"
            show-word-limit
            oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
          />
          <span class="placeholder">邮编</span>
        </div>
      </div>
      <!-- 地区选择器 -->
      <city-picker
        :hidden.sync="hiddenCityPicker"
        :address="shippingAddress"
        @change="mainAddressChange"
      ></city-picker>
    </div>
  </zw-dialog>
</template>

<script>
import cityPicker from "@/components/user/picker/city-picker.vue";

export default {
  components: {
    cityPicker,
  },
  props: {
    hidden: {
      type: Boolean,
      default: false,
    },
    address: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      shippingAddress: {},
      focusIndex: -1,
      oldPhone: "",
      hiddenCityPicker: true,
      loading: false,
      addressInfo: {},
      validatePhoneTxt: "",
    };
  },
  watch: {
    hidden(e) {
      if (e) {
        this.hiddenCityPicker = true;
        this.loading = false;
        this.validatePhoneTxt = "";
      } else {
        this.shippingAddress = {
          ...this.address,
        };
        // const {City,CityCode,District,DistrictCode,Province,ProvinceCode,Town,TownCode}=e
        // this.addressInfo={City,CityCode,District,DistrictCode,Province,ProvinceCode,Town,TownCode}
        if (this.address.AddressId) {
          this.oldPhone = this.address.PhoneNumber;
          // this.shippingAddress.phone = "";
        } else {
          this.oldPhone = "";
        }
      }
    },
    address(e) {},
  },
  computed: {
    title() {
      return `${this.address.Address ? "修改" : "添加"}收货地址`;
    },
    mainAddress() {
      if (this.shippingAddress.District) {
        let address =
          this.shippingAddress.Province +
          " " +
          this.shippingAddress.City +
          " " +
          this.shippingAddress.District;
        if (this.shippingAddress.Town) {
          address += " " + this.shippingAddress.Town;
        }
        return address;
      }
      return "";
    },
  },
  methods: {
    inputPhone(value) {
      value = value.replace(/[^\d]/g, "")
      this.shippingAddress.PhoneNumber = value
    },
    closeDialog() {
      this.$emit("update:hidden", true);
    },
    mainAddressChange(e) {
      this.shippingAddress = {
        ...Object.assign(this.shippingAddress, e),
      };
      this.hiddenCityPicker = true;
    },
    submit() {
      if (!this.checkForm()) return;
      if (!this.shippingAddress.Tag) this.shippingAddress.Tag = "";
      this.loading = true;
      this.$emit("change", this.shippingAddress);
    },
    checkForm() {
      if (!this.$regular.nickName.test(this.shippingAddress.Name)) {
        return this.notifyError("请输入正确的收货人姓名");
      }
      if (
        !this.$regular.phoneNumber.test(this.shippingAddress.PhoneNumber) &&
        (this.shippingAddress.PhoneNumber || !this.oldPhone)
      ) {
        return this.notifyError("请输入正确的收货人手机号");
      }
      if (!this.mainAddress) {
        return this.notifyError("请选择省市区");
      }
      if (
        !this.shippingAddress.Address ||
        this.shippingAddress.Address.length < 5
      ) {
        return this.notifyError("请输入正确的详细地址");
      }
      return true;
    },
    cityPickerEvent() {
      console.log(this.hiddenCityPicker, 111);
    },
    checkPhone() {
      this.focusIndex = -1;
      this.validatePhoneTxt = "";
      if (
        !this.$regular.phoneNumber.test(this.shippingAddress.PhoneNumber) &&
        (this.shippingAddress.PhoneNumber || !this.oldPhone)
      ) {
        this.validatePhoneTxt = "请输入正确的收货人手机号";
      }
    },
  },
};
</script>

<style lang="scss" >
.form-wrap {
  width: 100%;
  padding: 20px;
  position: relative;

  .form-row {
    margin-bottom: 14px;
    @extend %flex-align-center;

    .form-item {
      flex: 1;
      position: relative;
      //   border: 1px solid $border-color;
      transition: border-color 0.2s linear;

      &:hover {
        border-color: #b0b0b0;
      }

      &.focus {
        border-color: $color-primary;

        .placeholder {
          font-size: 12px;
          top: -11px;
          color: $color-primary;
        }
      }

      &.active {
        &.validate {
          .el-input__inner {
            border-color: #ff0000;
          }
          .placeholder {
            color: #ff0000;
          }
        }
        .placeholder {
          font-size: 12px;
          top: -11px;
        }
      }

      &.address {
        .el-input {
          &.is-disabled {
            .el-input__inner {
              background-color: #fff;
              cursor: pointer;
            }
          }
        }
      }

      &.long-text {
        display: flex;

        .textarea {
          //   height: 3em;
          resize: vertical;
          //   padding: 10px 16px;
          flex: 1;
        }
      }

      &:nth-child(2) {
        margin-left: 14px;
      }

      .placeholder {
        position: absolute;
        left: 12px;
        top: 11px;
        z-index: 1;
        padding: 0 3px;
        line-height: 18px;
        background-color: $bg-color;
        color: #b0b0b0;
        cursor: text;
        transition: all 0.2s linear;
      }

      .i-right {
        position: absolute;
        top: 50%;
        right: 6px;
        height: 24px;
        line-height: 24px;
        margin-top: -12px;
        font-size: 18px;
        color: #b0b0b0;
        font-weight: bold;
        transform: rotate(90deg);
      }
    }
  }
}

</style>
