var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('zw-dialog',{attrs:{"hidden":_vm.hidden,"title":_vm.title,"hideFooter":false,"width":660,"height":300},on:{"close":_vm.closeDialog,"cancel":_vm.closeDialog,"confirm":_vm.submit}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"form-wrap"},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-item",class:[
          { focus: _vm.focusIndex == 0 },
          { active: _vm.shippingAddress.Name } ],on:{"click":function($event){_vm.focusIndex = 0}}},[_c('el-input',{attrs:{"maxlength":"20","focus":_vm.focusIndex == 0,"show-word-limit":""},on:{"blur":function($event){_vm.focusIndex = -1}},model:{value:(_vm.shippingAddress.Name),callback:function ($$v) {_vm.$set(_vm.shippingAddress, "Name", $$v)},expression:"shippingAddress.Name"}}),_c('span',{staticClass:"placeholder"},[_vm._v("姓名")])],1),_c('div',{staticClass:"form-item",class:[
          { focus: _vm.focusIndex == 1 },
          { active: _vm.shippingAddress.PhoneNumber },
          { validate: _vm.validatePhoneTxt } ],on:{"click":function($event){_vm.focusIndex = 1}}},[_c('el-input',{attrs:{"value":_vm.shippingAddress.PhoneNumber,"type":"text","maxlength":"11","placeholder":_vm.focusIndex == 1 ? _vm.oldPhone : '',"placeholder-style":"color: #b0b0b0;","focus":_vm.focusIndex == 1,"show-word-limit":""},on:{"blur":_vm.checkPhone,"input":_vm.inputPhone}}),_c('span',{staticClass:"placeholder"},[_vm._v(_vm._s(_vm.validatePhoneTxt ? _vm.validatePhoneTxt : "手机号"))])],1)]),_c('div',{staticClass:"form-row",on:{"click":function($event){_vm.hiddenCityPicker = false}}},[_c('div',{staticClass:"form-item address"},[_c('el-input',{attrs:{"value":_vm.mainAddress,"placeholder":"选择省 / 市 / 区 / 街道","readonly":"true"}}),_c('i',{staticClass:"icon i-right",staticStyle:{"cursor":"pointer"}})],1)]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-item long-text",class:[
          { focus: _vm.focusIndex == 2 },
          { active: _vm.shippingAddress.Address } ],on:{"click":function($event){_vm.focusIndex = 2}}},[_c('el-input',{staticClass:"textarea",attrs:{"type":"textarea","maxlength":"50","placeholder":_vm.focusIndex == 2 ? '详细地址，路名或街道名称，门牌号' : '',"placeholder-style":"color: #b0b0b0;","focus":_vm.focusIndex == 2,"show-word-limit":""},on:{"blur":function($event){_vm.focusIndex = -1}},model:{value:(_vm.shippingAddress.Address),callback:function ($$v) {_vm.$set(_vm.shippingAddress, "Address", $$v)},expression:"shippingAddress.Address"}}),_c('span',{staticClass:"placeholder"},[_vm._v("详细地址")])],1)]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-item",class:[{ focus: _vm.focusIndex == 3 }, { active: _vm.shippingAddress.Tag }],on:{"click":function($event){_vm.focusIndex = 3}}},[_c('el-input',{attrs:{"maxlength":"5","placeholder":_vm.focusIndex == 3
              ? '如"家"、"公司"。限5个字内'
              : '',"placeholder-style":"color: #b0b0b0;","focus":_vm.focusIndex == 3,"show-word-limit":""},on:{"blur":function($event){_vm.focusIndex = -1}},model:{value:(_vm.shippingAddress.Tag),callback:function ($$v) {_vm.$set(_vm.shippingAddress, "Tag", $$v)},expression:"shippingAddress.Tag"}}),_c('span',{staticClass:"placeholder"},[_vm._v("地址标签")])],1)]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-item",class:[
          { focus: _vm.focusIndex == 4 },
          { active: _vm.shippingAddress.PostCode } ],on:{"click":function($event){_vm.focusIndex = 4}}},[_c('el-input',{attrs:{"maxlength":"6","placeholder":_vm.focusIndex == 4 ? '邮政编号' : '',"placeholder-style":"color: #b0b0b0;","focus":_vm.focusIndex == 4,"show-word-limit":"","oninput":"value=value.replace(/^\\.+|[^\\d.]/g,'')"},on:{"blur":function($event){_vm.focusIndex = -1}},model:{value:(_vm.shippingAddress.PostCode),callback:function ($$v) {_vm.$set(_vm.shippingAddress, "PostCode", $$v)},expression:"shippingAddress.PostCode"}}),_c('span',{staticClass:"placeholder"},[_vm._v("邮编")])],1)]),_c('city-picker',{attrs:{"hidden":_vm.hiddenCityPicker,"address":_vm.shippingAddress},on:{"update:hidden":function($event){_vm.hiddenCityPicker=$event},"change":_vm.mainAddressChange}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }