<template>
  <div>
    <!-- 左侧导航栏 -->
    <menu-wrapper active-index="">
      <div class="secondTitle">收货地址</div>
      <div class="address-wrap" v-loading="loading">
        <div
          :class="item.IsDefault ? 'box box-def' : 'box'"
          v-for="(item, index) in addresses"
          :key="index"
        >
          <div class="superscript" v-if="item.IsDefault">
            <div class="gradual"></div>
            <div class="desc">默认</div>
          </div>
          <div class="box-address">
            <!--@click="addressChange(item)" -->
            <div class="top">
              <div class="user-name">{{ item.Name }}</div>
              <div class="address-label">{{ item.Tag }}</div>
            </div>
            <div class="phone-number">
              {{
                item.PhoneNumber.replace(
                  $regular.addressPhone,
                  $regular.adderssPhoneText
                )
              }}
              <span>{{ item.PostCode }}</span>
            </div>

            <div class="address-detail">
              <div class="detail" v-if="item.Province&&item.City&&item.District ">
                {{ item.Province }} {{ item.City }} {{ item.District }}
                {{ item.Town }}
                {{ item.Address }}
              </div>
              <div class="alert-warn" v-else>
                <el-alert
                  title="请完善地址"
                  type="warning"
                  show-icon
                  :closable="false"
                >
                </el-alert>
              </div>
            </div>
          </div>
          <div class="btn-wrap">
            <div
              class="btn"
              v-if="!item.IsDefault"
              @click="addressChange(item, index)"
            >
              设置默认
            </div>
            <div class="btn" @click="editAddress(item, index)">修改</div>
            <div class="btn" @click="deleteAddress(item.AddressId)">删除</div>
          </div>
        </div>
        <div class="box box-add" @click="addAddress">
          <div class="btn-add">+</div>
          <div class="desc">添加新地址</div>
        </div>
      </div>
    </menu-wrapper>

    <!-- 添加/修改收货地址 -->
    <address-editer
      :hidden.sync="hiddenDialog"
      :address="address"
      @change="addressConfirm"
    ></address-editer>
  </div>
</template>

<script>
import menuWrapper from "@/components/user/common/menu-wrapper.vue";
import addressEditer from "@/components/user/editer/address-editer.vue";

export default {
  components: {
    menuWrapper,
    addressEditer,
  },
  data() {
    return {
      addresses: [],
      hiddenDialog: true,
      address: {},
      addressIndex: -1,
      defIndex: -1,
      loading: true,
    };
  },
  activated() {
    this.findAllAddress();
  },
  methods: {
    findAllAddress() {
      this.loading = true;
      this.$axios
        .get("/api/UserAddress/GetListAddress", "")
        .then((res) => {
          this.addresses = res.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    addAddress() {
      this.addressIndex = -1;
      this.address = {
        Name: "",
        PhoneNumber: "",
        Province: "",
        City: "",
        District: "",
        Town: "",
        Address: "",
        Tag: "",
        PostCode: "",
        ProvinceCode: 0,
        CityCode: 0,
        DistrictCode: 0,
        TownCode: 0,
      };
      this.hiddenDialog = false;
    },
    addressConfirm(e) {
      if (this.addressIndex == -1) {
        if (this.addresses.length == 0) {
          this.address.IsDefault = true;
        }
        this.$axios.post("/api/UserAddress/AddAddress", e).then((res) => {
          if (res.data) {
            this.addresses.push(res.data);
            this.hiddenDialog = true;
          }
        });
      } else {
        if (this.addresses.length == 1) {
          this.address.IsDefault = true;
        }
        this.$axios.put("/api/UserAddress/UpdateAddress", e).then((res) => {
          this.addresses[this.addressIndex] = e;
          this.hiddenDialog = true;
        });
      }
    },
    editAddress(item, index) {
      this.addressIndex = index;
      this.address = item;
      this.hiddenDialog = false;
    },
    deleteAddress(id) {
      this.$alert("确定删除该地址吗？", "", {
        confirmButtonText: "确定",
        closeOnClickModal: true,
      }).then(() => {
        this.loading = true;
        this.$axios
          .delete("/api/UserAddress/DeleteAddress?addressId=" + id, "")
          .then((res) => {
            if (res) {
              let item = this.addresses.find((i) => i.AddressId == id);
              this.addresses.remove(item);
            } else {
              this.$notifyError("删除地址错误，该地址不存在");
            }
            this.loading = false;
          });
      });
    },
    addressChange(item) {
      if (item.IsDefault) {
        return;
      }
      this.loading = true;
      const reqData = {
        addressId: item.AddressId,
      };
      this.$axios
        .post("/api/UserAddress/SetDefault", this.qs.stringify(reqData))
        .then((res) => {
          this.loading = false;
          this.addresses.forEach((items) => {
            items.IsDefault = false;
          });
          item.IsDefault = true;
          // this.notifySucceed("默认地址设置成功！");
        });
    },
  },
};
</script>

<style lang="scss" >
.title {
  font-size: 30px;
  color: #757575;
  line-height: 68px;
}

.address-wrap {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;

  .box {
    width: 268px;
    height: 178px;
    border: 1px solid $border-color;
    margin-right: 17px;
    margin-bottom: 20px;
    position: relative;
    transition: all 0.4s ease;

    &:hover {
      border-color: #b0b0b0;

      .btn-wrap {
        display: flex;
      }
    }

    &.box-add {
      @extend %flex-align-center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
      transition: all 0.4s ease;

      &:hover {
        .btn-add {
          background-color: #b0b0b0;
        }
      }

      .btn-add {
        width: 30px;
        height: 30px;
        font-size: 28px;
        line-height: 26px;
        background-color: $border-color;
        border-radius: 50%;
        color: #ffffff;
        text-align: center;
        transition: all 0.4s ease;
      }

      .desc {
        margin-top: 8px;
        color: #b0b0b0;
      }
    }

    .box-address {
      position: relative;
      padding: 15px 24px 0;

      .top {
        line-height: 30px;
        margin-bottom: 10px;
        @extend %flex-align-center;
        justify-content: space-between;

        .user-name {
          font-size: 18px;
          color: $text-color;
        }

        .address-label {
          color: #757575;
        }
      }

      .phone-number,
      .address-detail {
        line-height: 22px;
        color: #757575;
        span {
          float: right;
          margin-right: 10px;
        }
      }
    }

    .superscript {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 35px;
      height: 35px;
      overflow: hidden;

      /* 使用背景色渐变 */
      .gradual {
        width: 100%;
        height: 100%;
        background: linear-gradient(135deg, #f2270c 50%, transparent 50%);
      }

      .desc {
        position: absolute;
        top: 10%;
        //   left: 20%;
        z-index: 1;
        color: #ffffff;
        transform: rotate(-40deg);
        font-size: 10px;
      }
    }
    .btn-wrap {
      position: absolute;
      right: 24px;
      bottom: 10px;
      line-height: 22px;
      color: $color-primary;
      @extend %flex-align-center;
      display: none;

      .btn {
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }
  .box-def {
    border-color: #f2270c;
  }
}
</style>
